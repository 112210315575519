.row-0 {
  margin-right: 0px;
  margin-left: 0px;
  > [class*='col-'] {
    padding-right: 0px;
    padding-left: 0px;
  }
}
.row-6 {
  margin-right: -3px;
  margin-left: -3px;
  > [class*='col-'] {
    padding-right: 3px;
    padding-left: 3px;
  }
}
.row-10 {
  margin-right: -5px;
  margin-left: -5px;
  > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.row-16 {
  margin-right: -8px;
  margin-left: -8px;
  > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }
}
.row-20 {
  margin-right: -10px;
  margin-left: -10px;
  > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.row-30 {
  margin-right: -15px;
  margin-left: -15px;
  > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.row-40 {
  margin-right: -20px;
  margin-left: -20px;
  > [class*='col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
}