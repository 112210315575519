@font-face {
    font-family: 'icomoon';
    src:    url('fonts/icomoon.eot?a11z2j');
    src:    url('fonts/icomoon.eot?a11z2j#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?a11z2j') format('truetype'),
        url('fonts/icomoon.woff?a11z2j') format('woff'),
        url('fonts/icomoon.svg?a11z2j#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-hi-menu:before {
    content: "\e914";
}
.icon-menu:before {
    content: "\e910";
}
.icon-hi-global:before {
    content: "\e912";
}
.icon-hi-english:before {
    content: "\e913";
}
.icon-hi-close:before {
    content: "\e905";
}
.icon-hi-hz-reizouko:before {
    content: "\e911";
}
.icon-hi-search:before {
    content: "\e901";
}
.icon-hi-hz-suchikon:before {
    content: "\e90f";
}
.icon-hi-hz-comment:before {
    content: "\e90c";
}
.icon-hi-hz-hoshizaki:before {
    content: "\e90d";
}
.icon-hi-hz-meeting:before {
    content: "\e90e";
}
.icon-hi-hz-paper:before {
    content: "\e90b";
}
.icon-hi-hz-penguin:before {
    content: "\e90a";
}
.icon-hi-pdf:before {
    content: "\e900";
}
.icon-hi-html:before {
    content: "\e909";
}
.icon-hi-anc-r:before {
    content: "\e902";
}
.icon-hi-blank:before {
    content: "\e903";
}
.icon-hi-book:before {
    content: "\e904";
}
.icon-hi-good:before {
    content: "\e906";
}
.icon-hi-home:before {
    content: "\e907";
}
.icon-hi-office:before {
    content: "\e908";
}

