.overlay {
	visibility: hidden;
	&--bg {
		position: fixed;
		background-color: rgba(0,0,0,0.6);
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 100;
		opacity: 0;
		transition: opacity 0.6s;
	}
	&--content {
		z-index: 101;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.3s;
	}
}
